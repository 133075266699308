import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  },
  small: {
    width: 20,
    height: 20,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
  },
  large: {
    width: 40,
    height: 40,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 19,
  },
  avatar: {
    border: '1px solid #02224B',
  },
  basic: {
    width: 80,
    height: 65,
    backgroundColor: '#1A4A87',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  complex: {
    width: '100%',
    color: '#fff',
    backgroundColor: '#1A4A87',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    padding: '13px 15px',
    '& p': {
      '& span': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 14,
      }
    }
  },
  cpx: {
    marginRight: 10
  }
}));


export default function Avatars(props) {
  const classes = useStyles();
  const{img, size, title, clase, types, auth} = props;
  const name = title.split(' ');

  return (
    <div className={classes.root}>
      {types === 'simple' && img ? (
        <Avatar 
          alt={title}
          src={img} 
          className={[size == 'sm' ? classes.small : (size == 'lg' ? classes.large : ''), clase, img && classes.avatar].join(" ")} 
        />
      ):(types === 'simple' && !img ? (
        <Avatar 
          alt={title}
          className={[size == 'sm' ? classes.small : (size == 'lg' ? classes.large : ''), clase, img && classes.avatar].join(" ")} 
        >{name[0][0]}{name[1] && name[1][0] }</Avatar>
      ):(types === 'basic' && img ? (
        <div className={classes.basic}>
          <Avatar 
            alt={title}
            src={img} 
            className={[classes.large, img && classes.avatar].join(" ")} 
          />
        </div>
      ):(types === 'basic' && !img ? (
        <div className={classes.basic}>
          <Avatar 
            alt={title}
            className={classes.large}
          >{name[0][0]}{name[1] && name[1][0] }</Avatar>
        </div>
      ):(types === 'complex' && img ? (
        <div className={classes.complex + ' avatarContainer'}>
          <Avatar 
            alt={title}
            src={img} 
            className={[classes.large, img && classes.avatar, classes.cpx].join(" ")} 
          />
            <Typography style={{ textAlign: 'left' }}>Hola
            <br/>
            <span>{title}</span></Typography>
        </div>
      ):(types === 'complex' && !img ? (
        <div className={classes.complex + ' avatarContainer'}>
          <Avatar 
            alt={title}
            className={[classes.large, classes.cpx].join(" ")} 
          >{name[0][0]}{name[1] && name[1][0] }</Avatar>
            <Typography style={{ textAlign: 'left' }}>Hola
            <br/>
            <span>{title}</span></Typography>
        </div>
      ):'')))))}
    </div>
  );
}
