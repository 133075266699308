import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
    buttonx: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: '#02224B',
      fontWeight: 500,
      fontSize: 14,
      transition: '200ms ease-in-out',
      '&:hover': {
        backgroundColor: '#02224B !important',
        color: '#37ED9A !important',
        transition: '300ms ease-in-out',
      }
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
    btnIcons:{
      display: 'flex',
      alignItems: 'center'
    }
  }));

function CardIcon(props) {
    const{fill, stroke}=props;
    return (
      <SvgIcon {...props}>
        <svg width="22" height="22" viewBox="0 0 27 20" fill={fill ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
          <path d="M22.6515 1.57568H4.34868C3.08606 1.57568 2.0625 2.59924 2.0625 3.86186V15.3062C2.0625 16.5688 3.08606 17.5924 4.34868 17.5924H22.6515C23.9141 17.5924 24.9377 16.5688 24.9377 15.3062V3.86186C24.9377 2.59924 23.9141 1.57568 22.6515 1.57568Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M2.0625 6.14795H24.9377" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M5.4917 10.7205H12.3637" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M5.4917 14.1631H15.7929" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </SvgIcon>
    );
  }
  function ArrowRightIcon(props) {
    const{fill, stroke}=props;
    return (
      <SvgIcon {...props} style={{marginLeft:"4px"}}>
        <svg width="15" height="22" viewBox="0 0 12 11" fill={fill ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
          <path d="M1.87793 5.59985H10.1113" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6.87793 9.26658L9.78904 6.34436C9.99598 6.13618 10.1122 5.85456 10.1122 5.56102C10.1122 5.26748 9.99598 4.98587 9.78904 4.77769L6.88905 1.87769" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </SvgIcon>
    );
  }

  export default function ButtonIcons(props) {
    const{disabled, label, sizes, to, startIcon, endIcon}=props;
    const classes = useStyles();


    return (
        <div className={classes.btnIcons}>
            <Button
              size={sizes}
              className={classes.buttonx + (disabled ? ' buttonDisabled' : '')}
              variant="contained"
              startIcon={startIcon || <CardIcon stroke={disabled ? "#fff" : "#02224B"} />}
              endIcon={endIcon || <ArrowRightIcon stroke={disabled ? "#fff" : "#02224B"} />}
              disableElevation
              disabled={disabled}
              component={RouterLink}
              to={to}
            >
              {label}
            </Button>
        </div>);
  }