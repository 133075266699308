import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useHttpClient } from "./hooks/http-hook";

import { makeStyles } from "@material-ui/core/styles";

import { Hidden, ThemeProvider, Grid } from "@material-ui/core";
import theme from "./theme";


//Components
import Header from "./components/commons/Header";
import HeaderLogo from "./components/commons/HeaderLogo";
import BottomNav from "./components/commons/BottomNav";
import Sidebar from "./components/commons/Sidebar";


const ConsultaDatos = React.lazy(() => import("./pages/ConsultaDatos"));
const Confirmacion = React.lazy(() => import("./pages/Confirmacion.js"));
/*--------------------------------------------*/

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    background: "#f5f5f5",
  },
  contents: {
    background: "#f5f5f5",
    padding: "20px 45px",
    marginTop: 70,
    // marginBottom: 120,
    minHeight: "calc(100vh - 300px)",
  },
  content2: {
    flexGrow: 1,
    padding: "0",
  },
  root: {
    display: "flex",
    height: "100vh",
    background: "linear-gradient(180deg, #083876 0%, #02224B 100%)",
  },
  root2: {
    display: "flex",
    background: "#F9F9F9",
    padding: 5,
    height: "100vh",
  },
  root3: {
    display: "flex",
  },
  toolbar: {
    background: "transparent",
    width: "calc(100% - 300px)",
    zIndex: 20,
    position: "fixed",
    right: 0,
  },
  toolbar2: {
    background: "transparent",
    width: "100%",
    zIndex: 20,
    right: 0,
  },
}));

function App() {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const pathname = window.location.pathname;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    console.log(open);
    setOpen(!open);
  };



  let routes = (
      <>
        <Switch>
          <Route path={["/", "/home"]} exact component={ConsultaDatos}/>
          <Route path="/confirmacion/:code?" exact component={Confirmacion} />
          <Redirect to="/" />
        </Switch>
      </>
    );

  return (
    <>
        <Router>
          <ThemeProvider theme={theme}>
            {" "}
              <div
                className={classes.root2}
              >
                <Suspense fallback={<div className="center"></div>}>
                  <div className={classes.content2}>
                    <div className={classes.toolbar2}>
                        <HeaderLogo />
                    </div>
                    <div className= "mobileContainer"
                    >
                      {routes}
                    </div>
                  </div>
                </Suspense>
              </div>
          </ThemeProvider>
        </Router>
    </>
  );
}

export default App;
