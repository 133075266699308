import React , {useContext} from "react";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context.js";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, Divider, IconButton, Hidden, SvgIcon } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import { ReactComponent as Exit } from "./icons/exitHome.svg";
import Avatar from './Avatar';
import '../../styles.css';

const useStyles = makeStyles((theme) => ({
  drawer: {
    background: 'linear-gradient(180deg, #083876 0%, #02224B 100%)',
    height: 'calc(100% - 40px)',
    zIndex: 3,
    [theme.breakpoints.up("sm")]: {
      width: 300,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 230,
    background: 'transparent',
    margin: '20px 35px',
    overflow: 'hidden',
    border: 'none',
  },
  toolbar: {
    '& img':{
      height: 40,
    }
  },
  p:{
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbarImg: {
    marginBottom: 35,
  },
  root: {
    flex: 1,
    width: 'calc(100% - 34px)',
    overflow: 'auto',
    
  },
  items:{
    color: '#FFFFFF',
    padding: '18px 0 18px 17px',
    '& span':{
      color: '#FFFFFF',
      '& svg':{
        paddingRight: 10,
      }
    },
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxSizing: "unset"
  },
  active: {
    background: '#02224B',
    borderRadius: 5,
    color: '#37ED9A !important',
    '&:hover':{
      background: '#02224B',
      borderRadius: 5,
    }
  },
  disabled:{
    color: '#8C8C8C',
    padding: '18px 0 18px 17px',
    '& span':{
      color: '#8C8C8C',
      '& svg':{
        paddingRight: 10,
      }
    },
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    
  },
  actived: {
    background: '#02224B',
    borderRadius: 5,
    '& span':{
      color: '#37ED9A',
    },
    '&:hover':{
      background: '#02224B',
      borderRadius: 5,
    }
  },
  itemActive: {
    backgroundColor: '#02224B',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '&:hover':{
      background: '#02224B',
    },
    paddingLeft: 8,
    paddingRight: 0,
  },
  ListActive: {
    backgroundColor: '#02224B',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    '&:hover':{
      background: '#02224B',
    },
    paddingLeft: 0,
  },
  nested: {
    padding: '15px 20px 15px 50px',
    backgroundColor: '#02224B',
    '& span':{
      color: '#ffffff',
    },
  },
  nestedDisabled: {
    padding: '15px 20px 15px 50px',
    backgroundColor: '#02224B',
    '& span':{
      color: '#8C8C8C',
    },
  },
  about: {
    color: "#D1D1D1",
    '& svg':{
      paddingRight: 12,
    }
  },
  divider:{
    height: 100,
  },
  listArrow:{
    marginRight: 17,
  },
  itemHelp:{
    paddingLeft: 17,
  },
  buttonList:{
    paddingLeft: 0,
    paddingRight: 0,
  }
}));

function HomeIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="19.5px" height="21.5px" viewBox="0 0 19.5 21.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
    </defs>
    <path stroke="currentColor" d="M0.8,7.8l9-7l9,7v11c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6h-14c-0.5,0-1-0.2-1.4-0.6
      c-0.4-0.4-0.6-0.9-0.6-1.4V7.8z"/>
    <path stroke="currentColor" d="M6.8,20.8v-10h6v10"/>
    </svg>
    
  );
}
function WalletIcon(){
  return (
    <svg style={{boxSizing: "unset"}} width="18px" height="18px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M15.3,1.5v1h-0.5v-1V0.9L14.1,1L5.6,3.3l0.1,1h10c1,0,1.8,0.8,1.8,1.8v9.7c0,1-0.8,1.8-1.8,1.8H2.3
      c-1,0-1.8-0.8-1.8-1.8V5.4c0-0.4,0.1-0.8,0.4-1.1c0.2-0.3,0.6-0.5,0.9-0.6c0,0,0,0,0,0L14,0.5c0.1,0,0.2,0,0.2,0
      C14.8,0.5,15.3,0.9,15.3,1.5z M15.8,4.8H2.3C1.5,4.8,1,5.3,1,6v9.7C1,16.5,1.5,17,2.3,17h13.5c0.7,0,1.3-0.5,1.3-1.3V6
      C17,5.3,16.5,4.8,15.8,4.8z M13.4,10.4c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4
      c-0.1,0.1-0.3,0.2-0.4,0.2s-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.4C13.3,10.7,13.3,10.6,13.4,10.4z"/>
    </svg>
  );
}
function TradeIcon(){
  return (
    <svg style={{boxSizing: "unset"}} width="24.3px" height="22.6px" viewBox="0 0 24.3 22.6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M5.5,0.8h13.4l4.3,4.9c0,0,1.4,3-2.2,4.1c0,0-3.2,0.2-3.2-2.8c0,0,0.5,2.8-2.7,2.8S12.2,7,12.2,7
      S12,9.8,9.4,9.8S6.6,6.9,6.6,6.9S6.5,9.8,3.7,9.8S0.4,6.2,1.3,5.3S5.5,0.8,5.5,0.8z"/>
    <g>
      <polyline stroke="currentColor" points="21.9,12.1 21.9,21.8 23.5,21.8 0.8,21.8 2.4,21.8 2.4,12.1 	"/>
      <path stroke="currentColor" d="M5.4,12.7v5.6c0,0.3,0.2,0.5,0.5,0.5h12.5c0.3,0,0.5-0.2,0.5-0.5v-5.6"/>
    </g>
    </svg>
  );
}
function CollectIcon(){
  return (
    <svg style={{boxSizing: "unset"}} width="24px" height="18.1px" viewBox="0 0 24 18.1"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M14.4,0c-1.3,0-2.5,0.5-3.4,1.4l-0.3-0.2c-0.4-0.3-0.8-0.4-1.2-0.5c-0.4,0-0.9,0-1.3,0.2l0,0L4.8,3.1
      C4.6,3.2,4.4,3.2,4.2,3.2H4V3.1C4,2.9,4,2.8,3.9,2.7C3.8,2.6,3.7,2.5,3.5,2.5h-3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1
      c0,0-0.1,0.1-0.1,0.2S0,3,0,3.1v7c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.1s0.1,0,0.2,0h3
      c0.1,0,0.3-0.1,0.4-0.1C4,10.3,4,10.2,4,10.1v0H5c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1-0.1-0.3-0.1-0.4
      C5.2,9.1,5.1,9,5,9H4V4.3h0.1c0.4,0,0.8-0.1,1.1-0.3c0,0,0,0,0,0l3.3-2.2c0.2-0.1,0.5-0.2,0.7-0.1c0.3,0,0.5,0.1,0.7,0.3l0.2,0.2
      l0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3C9.7,3.1,9.5,3.7,9.4,4.4c-0.3,0-0.7,0.2-0.9,0.4C8.3,5,8.2,5.1,8.2,5.3S8,5.7,8,5.9
      C8,6.3,8.2,6.7,8.5,7s0.7,0.5,1.1,0.5h3.8c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.3,0.2C14,7.8,14,7.9,14.1,7.9c0,0.1,0.1,0.2,0.1,0.3
      c0,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0-0.1,0h0c-0.1,0-0.1,0-0.2,0H9.1C9,9,8.9,9,8.9,9.1c-0.1,0-0.1,0.1-0.2,0.1
      c0,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0h4.3
      c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.6,0c1.3,0,2.6-0.5,3.6-1.5c0.9-0.9,1.5-2.2,1.5-3.6c0-1.3-0.5-2.6-1.5-3.6C17,0.5,15.7,0,14.4,0z
      M3,9.6H1v-6h2V9.6z M9.1,5.9c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0,0.3,0.1,0.7,0.2,1
      c-0.1,0-0.3-0.1-0.4-0.2C9.1,6.2,9.1,6.1,9.1,5.9L9.1,5.9z M15,9c0.1-0.2,0.2-0.5,0.2-0.7c0-0.3-0.1-0.6-0.2-0.9
      c0.2,0,0.4-0.1,0.6-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4V5.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3H14
      c0,0,0,0,0,0c0,0,0,0,0,0V3.7c0,0,0,0,0,0c0,0,0,0,0,0h0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1
      s0.3-0.1,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.4V2.5c0-0.1-0.1-0.3-0.1-0.4
      C14.7,2,14.5,2,14.4,2S14.1,2,14,2.1s-0.1,0.2-0.1,0.4v0.2c-0.3,0-0.5,0.2-0.7,0.4c-0.2,0.2-0.3,0.4-0.3,0.7v0.7
      c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0.8c0,0,0,0,0,0c0,0,0,0,0,0
      c0,0,0,0,0,0c0,0,0,0,0,0H14c0,0,0,0,0,0c0,0,0,0,0,0V6.3c0-0.1-0.1-0.3-0.1-0.4s-0.2-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0
      c-0.1,0-0.1,0.1-0.2,0.1C13,5.9,13,6,13,6.1c0,0.1,0,0.1,0,0.2v0.1c0,0,0,0.1,0,0.1h-2.3C10.5,6,10.4,5.5,10.4,5
      c0-0.8,0.2-1.5,0.6-2.1c0.1-0.2,0.2-0.4,0.4-0.5c0.4-0.4,0.8-0.8,1.4-1C13.3,1.1,13.8,1,14.4,1c1.1,0,2.1,0.4,2.8,1.2
      C18,2.9,18.4,4,18.4,5c0,1-0.3,1.9-1,2.6C16.8,8.4,15.9,8.8,15,9L15,9z"/>
    <path fill="currentColor" d="M23,9.2h-2.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v0.3c-0.9,0-1.7,0.3-2.4,0.7
      c-0.6,0.4-1.4,0.6-2.1,0.6h-2.6c-0.4,0-0.8,0.2-1.1,0.4c-0.3,0.3-0.5,0.7-0.5,1.1l-3.9-1.8c-0.4-0.2-0.8-0.2-1.3,0S5.1,12,4.9,12.4
      c-0.2,0.4-0.2,0.8-0.1,1.2C5,14,5.3,14.3,5.7,14.5l5.4,2.7c1,0.5,2.2,0.8,3.4,0.8c0.9,0,1.8-0.2,2.6-0.5l2.7-1
      c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3H23c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-6.2c0-0.3-0.1-0.5-0.3-0.7
      C23.5,9.4,23.2,9.2,23,9.2z M22.8,10.4v6H21v-6H22.8z M6,12.8c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0
      c5.8,2.6,5.8,2.6,6.1,2.6h3.8c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2h-3.8
      c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h2.6c0.9,0,1.9-0.2,2.7-0.7
      c0.6-0.3,1.2-0.5,1.9-0.6v3.7l-3.1,1.2c-0.8,0.3-1.7,0.4-2.6,0.4c-0.9,0-1.7-0.3-2.5-0.7l-5.4-2.7C6.1,13.4,6,13.3,6,13.2
      C5.9,13.1,5.9,13,6,12.8z"/>
    </svg>

  );
}
function UserIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="17.5px" height="19.5px" viewBox="0 0 17.5 19.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M16.8,18.8v-2c0-1.1-0.4-2.1-1.2-2.8c-0.8-0.8-1.8-1.2-2.8-1.2h-8c-1.1,0-2.1,0.4-2.8,1.2
      c-0.8,0.8-1.2,1.8-1.2,2.8v2"/>
    <path stroke="currentColor" d="M8.8,8.8c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4C4.8,7,6.5,8.8,8.8,8.8z"/>
    </svg>
  );
}
function SecurityIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="19.5px" height="21.5px" viewBox="0 0 19.5 21.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M16.8,9.8h-14c-1.1,0-2,0.9-2,2v7c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7C18.8,10.6,17.9,9.8,16.8,9.8z"/>
    <path stroke="currentColor" d="M4.8,9.8v-4c0-1.3,0.5-2.6,1.5-3.5c0.9-0.9,2.2-1.5,3.5-1.5s2.6,0.5,3.5,1.5c0.9,0.9,1.5,2.2,1.5,3.5v4"/>
    </svg>
  );
}
function HelpIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="19.5px" height="18.7px" viewBox="0 0 19.5 18.7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M14.5,4.5V5H15h3c0,0,0,0,0,0c0.3,0,0.5,0.1,0.7,0.3C18.9,5.5,19,5.7,19,6v12c0,0,0,0.1,0,0.1
      c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0s-0.1,0-0.1-0.1l-3.5-2.8L15,15.3h-0.2H6c-0.3,0-0.5-0.1-0.7-0.3C5.1,14.8,5,14.5,5,14.2
      c0,0,0,0,0,0l0-2.8l0-1L4.2,11l-3.3,2.7l0,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1l0,0v-12
      c0-0.3,0.1-0.5,0.3-0.7C1,0.6,1.2,0.5,1.5,0.5h12c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7V4.5z M4.3,10.3L4.3,10.3
      c0,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0h9H14V9.7V1.5V1h-0.5h-12H1v0.5v10.4v1l0.8-0.7L4.3,10.3z M15.2,14.8L15.2,14.8l2.5,2l0.8,0.7v-1
      l0-10.4V5.5H18h-3h-0.5V6v3.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3H6H5.5l0,0.5l0,3l0,0.5H6h9c0,0,0,0,0,0
      C15.1,14.8,15.2,14.8,15.2,14.8z"/>
    </svg>
  );
}
function LogOutIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="19.5px" height="19.5px" viewBox="0 0 19.5 19.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="currentColor" d="M13.8,14.8l5-5l-5-5"/>
    <path stroke="currentColor" d="M18.8,9.8h-12"/>
    <path stroke="currentColor" d="M6.8,18.8h-4c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4v-14c0-0.5,0.2-1,0.6-1.4C1.7,1,2.2,0.8,2.8,0.8h4"
      />
    </svg>
  );
}
function LogoOnlyIcon() {
  return (
    <svg style={{boxSizing: "unset"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2.50019V17.5003C20 18.1637 19.7365 18.7998 19.2674 19.2689C18.7984 19.738 18.1622 20.0015 17.4989 20.0015H2.50021C1.83686 20.0015 1.20067 19.738 0.731607 19.2689C0.262548 18.7998 -0.000976562 18.1637 -0.000976562 17.5003V2.50019C-0.000976562 1.83684 0.262548 1.20065 0.731607 0.731592C1.20067 0.262533 1.83686 -0.000976562 2.50021 -0.000976562H17.5003C18.1634 -0.000586506 18.7992 0.263099 19.268 0.732116C19.7367 1.20113 20 1.8371 20 2.50019Z" fill="#37ED9A"/>
    <path d="M15.2007 6.32111H15.1973C15.1886 6.4226 15.1456 6.5181 15.0755 6.59197C15.0054 6.66585 14.9123 6.71369 14.8114 6.72766L12.8006 6.9768C12.7822 6.97916 12.7637 6.98032 12.7452 6.98024C12.6324 6.97912 12.5243 6.93533 12.4426 6.85768C12.3608 6.78003 12.3115 6.67428 12.3046 6.56174C12.2977 6.44921 12.3337 6.33824 12.4054 6.25119C12.477 6.16413 12.579 6.10745 12.6907 6.09257L13.6991 5.96751C12.4895 5.08481 11.0043 4.66354 9.51142 4.77973C8.01856 4.89592 6.6164 5.54192 5.55801 6.60113C5.47418 6.68451 5.36068 6.73117 5.24245 6.73085C5.12422 6.73053 5.01096 6.68324 4.92758 6.59941C4.84421 6.51558 4.79753 6.40207 4.79785 6.28384C4.79818 6.16561 4.84546 6.05235 4.92929 5.96898C6.12987 4.76731 7.71814 4.03098 9.41103 3.89123C11.1039 3.75147 12.7914 4.21736 14.1728 5.20588L14.0453 4.17598C14.0337 4.11602 14.0345 4.05432 14.0478 3.99471C14.061 3.93509 14.0864 3.87883 14.1222 3.82942C14.1581 3.78 14.2037 3.73847 14.2563 3.70742C14.3089 3.67638 14.3673 3.65646 14.4279 3.64892C14.4885 3.64138 14.55 3.64637 14.6086 3.66359C14.6672 3.6808 14.7217 3.70987 14.7686 3.74899C14.8154 3.7881 14.8538 3.83643 14.8812 3.89099C14.9087 3.94554 14.9246 4.00516 14.9281 4.06613L15.1949 6.21763V6.22793C15.1956 6.23887 15.1956 6.24984 15.1949 6.26078V6.26961L15.2007 6.32111Z" fill="#02224B"/>
    <path d="M15.2012 13.7164C15.2015 13.775 15.1901 13.8331 15.1677 13.8872C15.1453 13.9414 15.1124 13.9905 15.0708 14.0318C13.8701 15.2333 12.2818 15.9694 10.5889 16.1091C8.89608 16.2488 7.20864 15.7829 5.82727 14.7944L5.95479 15.8243C5.96517 15.8837 5.96334 15.9446 5.94946 16.0034C5.93557 16.0621 5.9099 16.1174 5.874 16.1659C5.83809 16.2144 5.79273 16.255 5.74061 16.2855C5.6885 16.3159 5.63075 16.3354 5.57086 16.3428C5.51098 16.3502 5.45021 16.3454 5.39225 16.3286C5.33429 16.3118 5.28035 16.2835 5.2337 16.2452C5.18704 16.2069 5.14864 16.1596 5.12085 16.106C5.09305 16.0525 5.07644 15.9938 5.072 15.9336L4.8052 13.7816V13.7714C4.80452 13.7602 4.80452 13.7491 4.8052 13.738V13.7292V13.6801H4.80865C4.81744 13.5787 4.86042 13.4832 4.93053 13.4094C5.00065 13.3355 5.09374 13.2876 5.1946 13.2736L7.20536 13.0244C7.22376 13.0223 7.24225 13.0214 7.26077 13.0215C7.37474 13.0206 7.48473 13.0635 7.56806 13.1412C7.65139 13.219 7.70171 13.3258 7.70869 13.4395C7.71566 13.5533 7.67874 13.6654 7.60554 13.7528C7.53234 13.8401 7.42845 13.8961 7.31522 13.9092L6.30689 14.0342C7.51637 14.917 9.00158 15.3383 10.4944 15.2222C11.9873 15.1061 13.3895 14.4602 14.448 13.4011C14.5102 13.3386 14.5896 13.2961 14.676 13.2788C14.7625 13.2616 14.8521 13.2703 14.9336 13.3041C15.015 13.3378 15.0847 13.3949 15.1336 13.4682C15.1826 13.5416 15.2087 13.6278 15.2086 13.7159L15.2012 13.7164Z" fill="#02224B"/>
    <path d="M12.1914 8.89258C12.4106 8.89258 12.6249 8.95757 12.8072 9.07936C12.9894 9.20115 13.1315 9.37426 13.2154 9.57679C13.2992 9.77932 13.3212 10.0022 13.2784 10.2172C13.2357 10.4322 13.1301 10.6297 12.9751 10.7847C12.8201 10.9397 12.6226 11.0452 12.4076 11.088C12.1926 11.1308 11.9697 11.1088 11.7672 11.0249C11.5647 10.941 11.3916 10.799 11.2698 10.6167C11.148 10.4344 11.083 10.2201 11.083 10.0009C11.083 9.70697 11.1998 9.42506 11.4076 9.2172C11.6155 9.00935 11.8974 8.89258 12.1914 8.89258Z" fill="white"/>
    <path d="M7.80954 8.89258C8.02875 8.89258 8.24305 8.95757 8.42532 9.07936C8.60759 9.20115 8.74963 9.37426 8.83352 9.57679C8.91741 9.77932 8.93935 10.0022 8.89659 10.2172C8.85382 10.4322 8.74828 10.6297 8.59328 10.7847C8.43827 10.9397 8.24077 11.0452 8.02577 11.088C7.81077 11.1308 7.58791 11.1088 7.38538 11.0249C7.18286 10.941 7.00977 10.799 6.88798 10.6167C6.7662 10.4344 6.70117 10.2201 6.70117 10.0009C6.70111 9.85536 6.72973 9.7112 6.7854 9.5767C6.84108 9.4422 6.92274 9.32 7.02568 9.21707C7.12861 9.11413 7.25082 9.03249 7.38532 8.97681C7.51983 8.92113 7.66397 8.89251 7.80954 8.89258Z" fill="white"/>
    </svg>
  );
}

const SideBar = (props) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const current = location.pathname;
  const [oBusiness, setOBusiness] = React.useState(false);
  const [oProfile, setOProfile] = React.useState(false);
  const [oCollects, setOCollects] = React.useState(false);
  const [oMovements, setOMovements] = React.useState(false);

  const handleClick = (opt) => {
    if(opt === 'business'){
      setOBusiness(!oBusiness);
      setOProfile(false);
      setOCollects(false);
      setOMovements(false);
    }else if(opt === 'profile'){
      setOProfile(!oProfile);
      setOCollects(false);
      setOBusiness(false);
      setOMovements(false);
    }else if(opt === 'movements'){
      setOMovements(!oMovements);
      setOProfile(false);
      setOCollects(false);
      setOBusiness(false);
    }
    else{
      setOCollects(!oCollects);
      setOProfile(false);
      setOBusiness(false);
      setOMovements(false)
    }
  };

  return (
    <Drawer
      variant={props.variant}
      classes={{
        paper: classes.drawerPaper,
      }}
      className={classes.drawer + ' mobileDrawer'}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      
      <Hidden smUp>
        <div className="drawerButton">
          <IconButton onClick={props.onClose ? props.onClose : null}><Exit /></IconButton>
        </div>
      </Hidden>

      <div className={classes.toolbar}>
        <img src={`/LogoTeoPagosWhite.svg`} className={classes.toolbarImg}/>
        <Avatar 
          title={`${auth.name} ${auth.lastname || ''}`}
          auth={auth}
          img={auth.photo ? auth.photo : "/avatars/avatar-1.jpg"}
          clase="primary"
          types="complex"
        />
      </div>
      <div className="navContainer">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          <ListItem button component={RouterLink} to="/" onClick={props.onClose} className={classes.buttonList}>
            <ListItemText
              className={[classes.items, current == '/dashboard' || current == '/notificaciones' ? classes.actived : classes.p].join(" ")}
              primary={<Typography 
                        variant="button" 
                        className="bodyMR textBtn"
                        >
                <div className="navIcon">
                  <HomeIcon />
                </div>
                  Inicio
                </Typography>}
            />
          </ListItem>
          
          <ListItem button 
            onClick={() => handleClick('movements')}
            className={[
              oMovements ? classes.itemActive : '', 
              current.indexOf("movimientos") > -1  ? classes.itemActive : (
              current.indexOf('acreditaciones') > -1 ? classes.itemActive : (
              current.indexOf('informes') > -1 ? classes.itemActive 
              : classes.p))].join(" ")}
         disabled={auth.profileId === 1 }
          >
            <ListItemText
              className={[
                      classes.items, 
                      oMovements ? classes.itemActive : ''
                    ].join(" ")}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <div className="navIcon">
                <WalletIcon />
                </div>
                  Movimientos
                </Typography>}
            />
            <div className={classes.listArrow}>
              {oMovements ? <ExpandLess 
                              className={[
                                classes.items, 
                                current.indexOf("movimientos") > -1 ? classes.itemActive : (
                                  current.indexOf('acreditaciones') > -1 ? classes.itemActive : (
                                  current.indexOf('informes') > -1 ? classes.itemActive : classes.p))
                              ].join(" ")}
                            /> :  <ExpandMore 
                                    className={[
                                      classes.items, 
                                      current.indexOf("movimientos") > -1 ? classes.itemActive : (
                                        current.indexOf('acreditaciones') > -1 ? classes.itemActive : (
                                        current.indexOf('informes') > -1 ? classes.itemActive : classes.p))
                                      ].join(" ")}
                                  />}
            </div>
          </ListItem>
          <Collapse in={oMovements} timeout="auto" unmountOnExit  className={classes.ListActive}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to={auth.profileId == 1 && !auth.upgrade ? "/habilitar-ventas" : "/movimientos-cobro"}
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={<Typography variant="button" className={["textSub", current.indexOf('movimientos-cobro') > -1 ? classes.active : ''].join(" ")}>Cobros</Typography>}
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to={auth.profileId == 1 && !auth.upgrade ? "/habilitar-ventas" : "/movimientos-pago"}
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current.indexOf('movimientos-pago') > -1 ? classes.active : ''].join(" ")}>Pagos</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/acreditaciones"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current.indexOf('acreditaciones') > -1 ? classes.active : ''].join(" ")}>Acreditaciones</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/informes/informes-financieros"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button"className={["textSub", current.indexOf('informes') > -1 ? classes.active : ''].join(" ")}>Extracto de Cuenta</Typography>
                  }
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button 
            onClick={() => handleClick('business')}
            className={[
              oBusiness ? classes.itemActive : '', 
              current == '/requisitos'  || current.includes('/requisito/') ? classes.itemActive : (
                current == '/plan' ? classes.itemActive : (
                current == '/colaboradores' ? classes.itemActive : (
                current.indexOf('/planes-suscripcion') > -1 ? classes.itemActive : 
                current == '/perfil-publico' ? classes.itemActive : classes.p)))
            ].join(" ")}
          >
            <ListItemText
              className={[
                      classes.items, 
                      oBusiness ? classes.itemActive : ''
                    ].join(" ")}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <div className="navIcon">
                  <TradeIcon />
                </div>
                  Mi Negocio
                </Typography>}
            />
            <div className={classes.listArrow}>
              {oBusiness ? <ExpandLess 
                              className={[
                                classes.items, 
                                current == '/requisitos' || current.includes('/requisito/') ? classes.itemActive : (
                                current == '/plan' ? classes.itemActive : (
                                current == '/colaboradores' ? classes.itemActive : (
                                current.indexOf('/planes-suscripcion') > -1 ? classes.itemActive :  ( current == 'perfil-publico' ? classes.itemActive : classes.p))))
                              ].join(" ")}
                            /> :  <ExpandMore 
                                    className={[
                                      classes.items, 
                                      current == '/requisitos' || current.includes('/requisito/') ? classes.itemActive : (
                                      current == '/plan' ? classes.itemActive: (
                                      current == '/colaboradores' ? classes.itemActive : ( 
                                      current.indexOf('/planes-suscripcion') > -1 ? classes.itemActive : (current == '/perfil-publico' ? classes.itemActive : classes.p))))
                                    ].join(" ")}
                                  />}
            </div>
          </ListItem>
          <Collapse in={oBusiness} timeout="auto" unmountOnExit  className={classes.ListActive}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to={auth.profileId == 1 && !auth.upgrade ? "/habilitar-ventas" : "/requisitos"}
                onClick={props.onClose}
              >
                <ListItemText
                  primary={<Typography variant="button" className={["textSub", current == '/requisitos' || current.includes('/requisito/') ? classes.active : ''].join(" ")}>Datos del Negocio</Typography>}
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/plan"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current =='/plan' ? classes.active : ''].join(" ")}>Mi plan</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/colaboradores-lista"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current.indexOf('colaboradores') > -1 ? classes.active : '']}>Colaboradores</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/planes-suscripcion-listado"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current.indexOf('/planes-suscripcion') > -1 ? classes.active : '']}>Planes de suscripción</Typography>
                  }
                />
              </ListItem>
             {/*  <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/metricas"
                onClick={props.onClose}
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current == '/metricas' ? classes.active : '']}>Métricas</Typography>
                  }
                />
              </ListItem> */}
              <ListItem
                button
                className={auth.profileId === 1 ? classes.nestedDisabled : classes.nested}
                component={RouterLink}
                to="/perfil-publico"
                onClick={props.onClose}
                disabled={auth.profileId === 1 }
              >
                <ListItemText
                  primary={
                    <Typography variant="button" className={["textSub", current.indexOf('perfil-publico') > -1 ? classes.active : ''].join(" ")}>Perfil Público</Typography>
                  }
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button 
            onClick={() => handleClick('collects')}
            className={[
              oCollects ? classes.itemActive : '', 
              current.indexOf("cobrar") > -1 ? classes.itemActive : classes.p].join(" ")}
            disabled={auth.profileId === 1 }
          >
            <ListItemText
              className={[auth.profileId === 1 ? classes.disabled :
                classes.items, 
                oCollects ? classes.itemActive : ''
              ].join(" ")}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <div className="navIcon">
                  <CollectIcon/>
                </div>
                  Cobrar
                </Typography>}
            />
            <div className={classes.listArrow}>
              {oCollects ? <ExpandLess 
                              className={[
                                classes.items, 
                                current.indexOf('cobrar') > -1 ? classes.itemActive : classes.p
                              ].join(" ")}
                            /> : <ExpandMore 
                                    className={[
                                      classes.items, 
                                      current.indexOf('cobrar') > -1 ? classes.itemActive : classes.p
                                    ].join(" ")}
                                  />}
            </div>
          </ListItem>
          <Collapse in={oCollects} timeout="auto" unmountOnExit className={classes.ListActive}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/cobrar/nuevo"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current.indexOf('nuevo') > -1 ? classes.active : ''].join(" ")}>Nuevo Cobro</Typography>
                }
              />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/cobrar/catalogo-listados"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current.indexOf('catalogo-') > -1 ? classes.active : ''].join(" ")}>Catálogo</Typography>
                }
              />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/cobrar/historico"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current == '/historico' ? classes.active : '']}>Historico de cobros</Typography>
                }
              />
              </ListItem>
            </List>
          </Collapse>
    
          <ListItem button 
            onClick={() => handleClick('profile')}
            className={[
              oProfile ? classes.itemActive : '', 
              current == '/datos-personales' ? classes.itemActive : (
              current == '/metodos-pago' ? classes.itemActive : (
              current == '/comunicaciones' ? classes.itemActive : (
              current == '/security' ? classes.itemActive : classes.p)))
            ].join(" ")}
          >
            <ListItemText
              className={[
                      classes.items, 
                      oProfile ? classes.itemActive : ''
                    ].join(" ")}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <div className="navIcon">
                  <UserIcon />
                </div>
                  Mi Cuenta
                </Typography>}
            />
            <div className={classes.listArrow}>
              {oProfile ? <ExpandLess 
                              className={[
                                classes.items, 
                                current == '/datos-personales' ? classes.itemActive : (
                                current == '/metodos-pago' ? classes.itemActive : (
                                current == '/comunicaciones' ? classes.itemActive : (
                                current == '/security' ? classes.itemActive : classes.p)))
                              ].join(" ")}
                            /> :  <ExpandMore 
                                    className={[
                                      classes.items, 
                                      current == '/datos-personales' ? classes.itemActive : (
                                      current == '/metodos-pago' ? classes.itemActive : (
                                      current == '/comunicaciones' ? classes.itemActive : (
                                      current == '/security' ? classes.itemActive : classes.p)))
                                    ].join(" ")}
                                  />}
            </div>
          </ListItem>
          <Collapse in={oProfile} timeout="auto" unmountOnExit className={classes.ListActive}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/datos-personales"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current == '/datos-personales' ? classes.active : ''].join(" ")}>Mis datos personales</Typography>
                }
              />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/metodos-pago"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current == '/metodos-pago' ? classes.active : ''].join(" ")}>Mis metodos de pago</Typography>
                }
              />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/comunicaciones"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current == '/comunicaciones' ? classes.active : ''].join(" ")}>Mis comunicaciones</Typography>
                }
              />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                component={RouterLink}
                to="/security"
                onClick={props.onClose}
              >
              <ListItemText
                primary={
                  <Typography variant="button" className={["textSub", current == '/security' ? classes.active : ''].join(" ")}>Seguridad</Typography>
                }
              />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            component={RouterLink}
            to="/help"
            onClick={props.onClose}
            className={[ 
              current == '/help'  ? classes.itemActive 
         : classes.p].join(" ")}
          >
            <ListItemText
              className={[classes.items, current == '/help' ? classes.actived : classes.p].join(" ")}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <div className="navIcon">
                  <HelpIcon/>
                </div>
                Ayuda
                </Typography>}
            />
          </ListItem>
          <div className={classes.divider}/>
          <ListItem
            button
            onClick={() => auth.logout(auth.teoId)}
          >
            <ListItemText
              className={classes.items}
              primary={<Typography variant="button" className="bodyMR textBtn">
                <LogOutIcon/>
                Cerrar Sesión
                </Typography>}
            />
          </ListItem>
          <div className={classes.divider}/>
          <ListItem
            className={classes.itemHelp}
            button
            component={RouterLink}
            to="/help"
            onClick={props.onClose}
          >
            <ListItemText
              primary={<Typography variant="button" className={classes.about + ' bodyXSR aboutNav'}>
                <LogoOnlyIcon/>
                Acerca de Teo Pagos
                </Typography>}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideBar;
