import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({});

const theme = createTheme({
    spacing: 8,
    typography: {
        htmlFontSize: 16,
        fontFamily: "'Rubik', sans-serif",
        fontSize: 14,
        color: "#000000",
        h1: {
            fontSize: 60, 
            lineHeight: '71.1px',
            fontWeight: 700,
        },
        h2: {
            fontSize: 50, 
            lineHeight: '59.25px',
            fontWeight: 700,
        },
        h3: {
            fontSize: 40, 
            lineHeight: '47.4px',
            fontWeight: 700,
        },
        h4: {
            fontSize: 34, 
            lineHeight: '40.29px',
            fontWeight: 700,
        },        
        h5: {
            fontSize: 24, 
            lineHeight: '28.44px',
            fontWeight: 700,
        },
        h6:{
            fontSize: 18, 
            lineHeight: '21.33px',
            fontWeight: 700,
        },
        body1:{
            fontSize: 16,
            lineHeight: '18.96px',
            fontWeight: 400,
            [breakpoints.up('sm')]: {
                fontSize: 14,
                lineHeight: '16.59px'
            },
            [breakpoints.down('xs')]: {
                fontSize: 12,
                lineHeight: '14.22px'
            }
        },
        body2:{
            fontSize: 16,
            lineHeight: '18.96px',
            fontWeight: 500,
            [breakpoints.up('sm')]: {
                fontSize: 14,
                lineHeight: '16.59px'
            },
            [breakpoints.up('xs')]: {
                fontSize: 12,
                lineHeight: '14.22px'
            }
        },
        subtitle1:{
            fontSize: 22,
            lineHeight: '1.15px',
            fontWeight: 700,
            [breakpoints.up('sm')]: {
                fontSize: 18,
                lineHeight: '21.33px',
                fontWeight: 500
            }
        },
        subtitle2:{
            fontSize: 20,
            lineHeight: '1.15px',
            fontWeight: 500,
            [breakpoints.up('sm')]: {
                fontSize: 18,
                lineHeight: '1.15px'
            }
        },
        button:{//Para los items del Drawer
            fontSize: 16,
            lineHeight: '18.96px',
            fontWeight: 400,
            [breakpoints.up('sm')]: {
                fontSize: 14,
                lineHeight: '16.59px'
            },
            color: "#000000",
            textTransform: 'uppercase',
        }
    },
    palette: {
        common: {
            black:'#000',
            white:'#fff'
        },
        type: "light",
        primary: {
            main: '#02224B',
            light: '#37ED9A',
            contrastText:'#fff'
        },
        secondary: {
            light: '#37E2ED',
            main: '#2C3E50',
            contrastText:'#000'
        },
        warning: {
            light: '#FED380',
            main: '#FFAF13',
            contrastText:'#fff'
        },
        danger: {
            light: '#FFE7EC',
            main: '#C80731',
            contrastText:'#fff'
        },
        error: {
            light: '#FFE7EC',
            main: '#C80731',
            contrastText:'#fff'
        },
        success: {
            light: '#AEFBD8',
            main: '#00BB66',
            contrastText:'#fff',
            borderColor: '#00BB66'
        },
        grey: {
            50:'#8C8C8C',
            100: '#D1D1D1',
            200: '#DDDDDD',
            300: '#E8E8E8',
            400: '#F3F3F3',
            500: '#F9F9F9'
        },
    },
    overrides: {
        MuiAvatar: {
            root: {
                width: 30,
                height: 30,
            },
            colorDefault: {
                color: '#000',
                backgroundColor: '#37ED9A',
                border: 'none',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 17,
            }
        },
        MuiChip: {
            root: {
                backgroundColor: 'unset',
                width:'auto',
                height: 19,
                fontSize: 10,
                lineHeight: 12,
            },
            label: {
                paddingLeft: 6,
                paddingRight: 6,
            }
        },
        MuiCard:{
            root: {
                boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.05)',
                background: '#ffffff',
                borderRadius: 10,
                '&:hover': {
                    boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.1)',
                },
                zIndex: 1,
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 45,
                paddingRight: 45,
                height: 70
            },
            colorPrimary: {
                backgroundColor: '#FFFFFF',
                color: '#000'
            }
        },
        MuiToolbar: {
            root: {
                minHeight: 0,
            },
            gutters: {
                paddingLeft:0,
                paddingRight:0,
                [breakpoints.up('sm')]: {
                    paddingLeft:0,
                    paddingRight:0,
                },
                [breakpoints.up('xs')]: {
                    paddingLeft:0,
                    paddingRight:0,
                }
            }
        },
        MuiButtonBase:{
            root: {
                backgroundColor: '',
                '&hover':{
                    backgroundColor: '',
                },
            },
         /*    '&$disabled': {
                color: '#fff'
            } */
        },
        MuiButton: {
            text:{
                padding:''
            },
            root:{
                width: 'auto',
                height: 36,
                padding: '10px 15px',
                borderRadius: 3,
                textTransform: 'none',
                '&hover':{
                    backgroundColor: '',
                }
            },
            sizeSmall:{
                width: 'auto',
                height: 26,
                padding: '5px 10px',
                letterSpacing: 1,
                borderRadius: 3,
                textTransform: 'none',
                '&hover':{
                    backgroundColor: '',
                }
            },
            sizeLarge: {
                width: 'auto',
                height: 46,
                padding: '15px 20px',
                borderRadius: 3,
                textTransform: 'none',
                '&hover':{
                    backgroundColor: '',
                }
            }
        },
        MuiIconButton: {
            // root: {
            //     width: 40,
            //     height: 40,
            // }
        },
        MuiSvgIcon: {
            root: {
                width: 'auto',
                //marginTop: 5,
            }
        },
        MuiBadge: {
            badge: {
                backgroundColor: '#37ED9A', 
                color:'#000',
            }
        },
        MuiOutlinedInput:{
            root:{
                borderRadius: 3,
                padding:0,
                '& $notchedOutline': {
                    border: 'none',
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: 'none',
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        border: 'none',
                    },
                },
                // "&$focused": {
                //     border: 'none',
                // }
            },
            input: {
                padding:0,
                border: 'none',
            },
            // focused: {
            //     border: 'none',
            // }
        },
        MuiInputBase:{
            root:{
                border: 'none',
            },
            inputMarginDense:{
                padding:0,
                border: 'none',
            },
            input: {
                border: 'none',
            },
        },
        MuiSwitch:{
            switchBase:{
                color: '#fff',
                padding: 3
            },
            sizeSmall: {
                width: '37px !important',
                height: '22px !important',
                padding: 3,
            }
        },
        MuiListItem:{
            root:{
                paddingTop: 0,
                paddingBottom: 0,
            },
            gutters:{
                paddingLeft: 0,
                paddingRight: 0,
            }
        },
        //NO DS
        MuiFormControl: {
          root: {
            zIndex: '0',//Para el bug raro del label del textfield
          }
        },
        MuiBottomNavigation:{
            root: {
                zIndex: '1',
            }
        },
        MuiToolbar: {
            regular: {
              '@media (min-width: 600px)': {
                justifyContent: "center"
              }
            },
        },
        MuiStepIcon: {
            root: {
                height: 35
            },
            completed: {
                color: '#37ED9A !important',
                
            }
        },
        MuiStepConnector: {
            lineHorizontal: {
                border: '1px solid #000'
            }
        },
        MuiList:{
            padding: {
                paddingTop: 'none',
                paddingBottom: 'none',
                
            }
        },
        MuiListItem: {
            root: {
                paddingLeft: 8
            }
        }
    }

});

export default theme;
