import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context.js";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

//Icons
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as Cobrar } from './icons/hand.svg';
import { ReactComponent as Yo } from './icons/yo.svg';


const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${290}px)`,
      marginLeft: 290,
    },
  },
  stick:{
    height: "80px",
    position: "fixed",
    bottom: 0,
    left: "0px",
    right: "0px",
    width: "100%",
  }
}));

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const auth = useContext(AuthContext);
  const history = useHistory();

  const yo = (e) => {
    console.log('yo');
    alert(`Hola, ${auth.name} ${auth.lastname} (Perfil: ${auth.profile})`);
  }
  const handleChange = (event, newValue) => {
    history.push(newValue);
    setValue(newValue);
  };


  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={`${classes.root} ${classes.stick}`}
    >
      <BottomNavigationAction value="/dashboard" label="Inicio" icon={<Home />} />
      <BottomNavigationAction value="/cobrar/links-pago" label="Cobrar" icon={<Cobrar />} />
      <BottomNavigationAction value="/datos-negocio" label="Yo" icon={<Yo />} onClick={yo}/>
    </BottomNavigation>
  );
}