import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
//customs
import ButtonIcons from './ButtonIcons';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const HeaderLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="headerLogo">
        <img src="../../LogoTeoPagos.svg" alt="TEO Pagos" />
      </div>
    </div>
  );
}
export default HeaderLogo;