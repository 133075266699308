import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Container from "@material-ui/core/Container";
import { Link, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: 5,
    overflow: "hidden",
    backgroundColor: "#F9F9F9",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.25)",
    },
    /*  marginRight: theme.spacing(2), */
    marginLeft: 0,
    width: "100%",
    height: 40,
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    backgroundColor: "#F9F9F9",
    cursor: "pointer !important",
  },
  inputRootDialog: {
    color: "inherit",
  },
  inputInputDialog: {
    padding: theme.spacing(1.5, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    /*  [theme.breakpoints.up("md")]: {
        width: "20ch",
      }, */
    backgroundColor: "#F9F9F9",
    cursor: "pointer !important",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  toolbars: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    color: "#02224B",
    fontWeight: 500,
    fontSize: 14,
    "&hover": {
      backgroundColor: "#02224B !important",
      color: "#37ED9A !important",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  categoryDataResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "6px 20px",
    borderBottom: "1px solid #DDDDDD !important",
    position: "sticky",
    zIndex: 1,
  },
  resultItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "4px 20px",
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
      /*  color: "#fff", */
    },
  },
  grey50: {
    color: `${theme.palette.grey[50]}`,
  },
  grey100: {
    color: `${theme.palette.grey[100]}`,
  },
  emptyResult: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  searchResult: {
    overflow: "hidden auto",
    minHeight: "50px",
    maxHeight: "80vh",
  },
  modalBoxMobile:{
    '@media (max-width: 700px)': 
    {
      height: "100% !important",
      width: "100% !important",
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
      margin: "auto !important"
    }
  },
  modalCloseDesktop:{
    '@media (min-width: 701px)':
    {display: "none"}
  }
}));
export default function SearchBarDialog(props) {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const classes = useStyles();
  const dataFunctionalities = require("./data_functionalities.json");
  const [dataResultApi, setDataResultApi] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [filteredDataFunctionalities, setFilteredDataFunctionalities] =
    useState(dataFunctionalities);
  const [filteredDataUser, setFilteredDataUser] = useState([]);
  const [openSearchDialog, setOpenSearchDialog] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    const loadDataApi = async () => {
      if (auth && openSearchDialog) {
        try {
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Token " + auth.token,
          };
          const response = await sendRequest(
            `${process.env.REACT_APP_TEOPAGOS_URI}/user/datas/`,
            "GET",
            null,
            headers
          );
          if (response) {
            console.log(response, "response");
            setDataResultApi(response);
          } else {
            setErrorMsg(response.errors.message);
          }
        } catch (e) {
          console.log("error:");
          console.log(e);
          setErrorMsg("Ha ocurrido un error.");
        }
      }
    };
    loadDataApi();
    setValueSearch("");
    setFilteredDataFunctionalities(dataFunctionalities.slice(0, 10));
    setFilteredDataUser([]);
  }, [openSearchDialog]);

  const handleOpenSearchDialog = () => {
    setOpenSearchDialog(true);
  };

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setValueSearch(searchWord);
    /*  const searchTerms = searchWord.split(" ") 
    let newFilterFunctionalities = []
    searchTerms.map(searchTerm => {
      let dataFilter = dataFunctionalities.filter(item => {
        return item.name
          .toLowerCase()
          .includes(
            searchTerm.toLowerCase()) ||
              item.search_terms.find(term => term.toLowerCase().includes(searchTerm.toLowerCase()))
      });
      newFilterFunctionalities.push(...dataFilter)
    }) */
    const newFilterDataUser = dataResultApi?.filter(
      (value) =>
        value.name.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.search_terms.find((term) =>
          term.toLowerCase().includes(searchWord.toLowerCase())
        )
    );

    const newFilterFunctionalities = dataFunctionalities.filter(
      (value) =>
        value.name.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.search_terms.find((term) =>
          term.toLowerCase().includes(searchWord.toLowerCase())
        )
    );

    if (searchWord === "") {
      setFilteredDataFunctionalities(dataFunctionalities.slice(0, 10));
      setFilteredDataUser([]);
    } else {
      setFilteredDataFunctionalities(newFilterFunctionalities);
      setFilteredDataUser(newFilterDataUser);
    }
  };

  const clearInput = () => {
    setFilteredDataFunctionalities(dataFunctionalities.slice(0, 10));
    setFilteredDataUser([]);
    setValueSearch("");
    const inputSearchField = document.getElementById("searchInput");
    inputSearchField.focus();
  };

  return (
    <>
    <div className="searchMobile" onClick={handleOpenSearchDialog}>
              <img src={"/icons/Search-2.svg"} />
            </div>
            <div className={classes.search + " searchDesktop"}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Buscar por..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        disabled
        onClick={handleOpenSearchDialog}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSearchDialog}>
          <Container maxWidth="sm" className={classes.modalBoxMobile}>
            <div className={"modalBox " + classes.modalBoxMobile}>
               <div className={"modalClose " + classes.modalCloseDesktop} onClick={handleCloseSearchDialog}><img src={"../icons/Close.svg"} /></div>
              <br/>
              <div className="searchBox">
                <div className={classes.search + " searchInput"}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    id="searchInput"
                    placeholder="Buscar por..."
                    classes={{
                      root: classes.inputRootDialog,
                      input: classes.inputInputDialog,
                    }}
                    autoFocus
                    value={valueSearch}
                    inputProps={{ "aria-label": "search" }}
                    fullWidth
                    onChange={handleFilter}
                    endAdornment={
                      valueSearch !== "" && (
                        <CloseIcon
                          id="clearBtn"
                          onClick={clearInput}
                          style={{
                            cursor: "pointer",
                            padding: "7px 16px",
                            backgroundColor: "#F9F9F9",
                          }}
                        />
                      )
                    }
                  />
                </div>
                <div className={classes.searchResult}>
                  {filteredDataUser?.length != 0 && (
                    <div className="dataResult">
                      <div
                        className={classes.categoryDataResult}
                        style={{ marginTop: 5 }}
                      >
                        <span className={["bodyXSR", classes.grey50].join(" ")}>
                          Resultados
                        </span>
                      </div>
                      {filteredDataUser.slice(0, 20).map((value, key) => {
                        return (
                          <Link
                            component={RouterLink}
                            to={value.url}
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                            className={classes.resultItem}
                            onClick={handleCloseSearchDialog}
                            key={key}
                          >
                            <div style={{ margin: 5 }}>
                              <p className="bodyXSR" style={{ margin: 0 }}>
                                {value.name}
                              </p>
                              {value.parent && value.parent !== undefined && (
                                <span className="bodyXSR grey">
                                  {value.parent}
                                </span>
                              )}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                  {filteredDataFunctionalities.length != 0 && (
                    <div>
                      <div
                        className={classes.categoryDataResult}
                        style={{ marginTop: 5 }}
                      >
                        <span className={["bodyXSR", classes.grey50].join(" ")}>
                          Accesos Directos
                        </span>
                      </div>
                      {filteredDataFunctionalities
                        .slice(0, 20)
                        .map((value, key) => {
                          return (
                            <Link
                              component={RouterLink}
                              to={value.url}
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                              className={classes.resultItem}
                              onClick={handleCloseSearchDialog}
                              key={key}
                            >
                              <div style={{ margin: 5 }}>
                                <p className="bodyXSR" style={{ margin: 0 }}>
                                  {value.name}
                                </p>
                                {value.parent && value.parent !== undefined && (
                                  <span className="bodyXSR grey">
                                    {value.parent}
                                  </span>
                                )}
                              </div>
                            </Link>
                          );
                        })}
                    </div>
                  )}
                  {filteredDataUser?.length === 0 &&
                    filteredDataFunctionalities.length === 0 && (
                      <div className={classes.emptyResult}>
                        <Typography variant="h6" align="left" color="grey">
                          No se han encontrado resultados
                        </Typography>
                        <br />
                        <span className={classes.grey50 + " bodySR"}>
                          Intenta usar otras palabras claves
                        </span>
                      </div>
                    )}
                  {/*  <div className='searchButton'>
                  <Button 
                    color="primary" 
                    variant="contained"
                    disableElevation
                    >Buscar</Button>
                </div> */}
                </div>
              </div>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
    </>

  );
}
